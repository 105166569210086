var LocationFilters = function ($) {

    function getUrlVars(url)
    {
        var vars = [], hash;
        var hashes = url.slice(url.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1];
        }

        return vars;
    }

    function filterEvents(filter) {
        $('.events-js').each(function() {
            if (filter === 'all' || $(this).hasClass('events-' + filter + '-js')) {
                $(this).slideDown();
            } else {
                $(this).slideUp();
            }
        });
    }

    function setActiveFilter($clickedElement) {
        $('.location-filters-js').each(function() {
            $(this).removeClass('location-filters__link--active');
        });

        $clickedElement.addClass('location-filters__link--active');
    }


    return {

        init: function () {

            $('.location-filters-js').on('click', function(event) {
                event.preventDefault();
                var href = $(this).attr('href');

                if (href) {
                    var urlVars = getUrlVars(href);
                    var filter = urlVars.filter;

                    if (filter) {
                        filterEvents(filter);
                        setActiveFilter($(this));
                    }
                }
            });
        }
    };
};
