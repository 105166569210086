var Slideshow = function ($) {

    return {

        init: function () {

            $(".slideshow-js").bxSlider({
                auto: true,
                controls: false,
                mode: 'fade',
                pager: false,
                speed: 1000,
                preloadImages: 'all'
            });
        }
    };
};