var EncryptedEmail = function ($) {

    return {

        init: function () {
            $('.icon-mail-js').on('click', function() {
                var trigger = this;
                var $codeElem = $(this).parent().next('.encrypted-email-js');
                var prefix = $codeElem.attr('data-prefix');
                if (prefix) {
                    prefix += '__';
                } else {
                    prefix = '';
                }
                var code = $codeElem.html();

                var self = this;

                $.ajax({
                    type: 'POST',
                    url: '/wp/wp-admin/admin-ajax.php',
                    data: {
                        action: 'kofmehl_get_decode_key'
                    },
                    success: function (data) {
                        if (data) {
                            data = JSON.parse(data);
                        } else {
                            return;
                        }
                        var result = '';
                        for (var i = 0; i < code.length; i++) {
                            if (data[code[i]]) {
                                result += data[code[i]];
                            } else {
                                result += code[i];
                            }
                        }
                        $(self).parent().append('<a href="mailto:' + result + '" class="' + prefix + 'email-address">' + result + '</a>');
                        $(self).remove();
                    },
                    error: function (XMLHttpRequest, textStatus, errorThrown) {
                        console.log(errorThrown);
                    }
                });
            });
        }
    };
};