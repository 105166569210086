var Body = function ($) {

  return {

    init: function () {

      $reservationForm = $('#reservation-form');
      if ($reservationForm) {

        var grcReservation = $reservationForm.attr('data-grc');

        $reservationForm.submit(function(event) {
          event.preventDefault();

          grecaptcha.ready(function() {
            grecaptcha.execute(grcReservation, {action: 'reservation'}).then(function(token) {
              $('#token').val(token);
              $reservationForm.unbind('submit').submit();
            });
          });
        });
      }

      $wettbewerbForm = $('#wettbewerb-form');
      if ($wettbewerbForm) {
        var grcWettbewerb = $wettbewerbForm.attr('data-grc');

        $wettbewerbForm.submit(function(event) {
          event.preventDefault();

          grecaptcha.ready(function() {
            grecaptcha.execute(grcWettbewerb, {action: 'wettbewerb'}).then(function(token) {
              $('#token').val(token);
              $wettbewerbForm.unbind('submit').submit();
            });
          });
        });
      }

      $joinForms = [$('#join-form-modal'), $('#join-form-widget')];
      $joinForms.forEach(function(form) {
        if (form) {
          var grcJoin = form.attr('data-grc');

          form.submit(function(event) {
            event.preventDefault();

            grecaptcha.ready(function() {
              grecaptcha.execute(grcJoin, {action: 'mitmachen'}).then(function(token) {
                form.find('#token').val(token);
                form.unbind('submit').submit();
              });
            });
          });
        }
      });
    }
  };
};
