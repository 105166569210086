var Navigation = function ($) {
    var $navigation = $('.navigation-js');
    var $trigger = $('.navigation-trigger-js');

    return {

        init: function () {

            $trigger.on('click', function() {
                $navigation.toggleClass('navigation--active');
                $trigger.toggleClass('navigation--active');
            });
        }
    };
};
