var DateFilter = function ($) {
    var BreakException = {};

    return {

        init: function () {
            //use photomatDates from DOM
            var dates = photomatDates;

            $('.date-filter-js').each(function() {
                var args = {
                    field: this,
                    format: 'DD.MM.YYYY',
                    firstDay: 1,
                    disableDayFn: function(date) {
                    var month = date.getMonth() + 1;
                    var day = date.getDate();
                    var year = date.getFullYear();

                    var enabled = false;
                    try {
                        dates.forEach(function(element) {
                            if (element.year === year && element.month === month && element.day === day) {
                                enabled = true;
                                throw BreakException;
                            }
                        });
                    } catch (e) {
                        if (e !== BreakException) {
                            throw e;
                        }
                    }

                    return !enabled;
                },
                    onSelect: function(date) {
                        var month = date.getMonth() + 1;
                        var day = date.getDate();
                        var year = date.getFullYear();
                        dates.forEach(function(element) {
                            if (element.year === year && element.month === month && element.day === day) {
                                location.href = element.link;
                            }
                        });
                    }
                };

                if (photomatMin) {
                    args.minDate = new Date(photomatMin);
                }
                if (photomatMax) {
                    args.maxDate = new Date(photomatMax);
                }

                new Pikaday(args);
            });
        }
    };
};