var PhotomatEdit = function ($) {

    return {

        init: function () {

            $('.photomat-edit-js').on('click', function() {
                $(this).toggleClass('photomat-edit__item--active');
                var $checkbox = $(this).find('.photomat-edit-checkbox-js');
                $checkbox.prop('checked', !$checkbox.is(':checked'));
            });
        }
    };
};