(function ($) {
  var Components = {
    'body': new Body($),
    'backend-wettbewerb': new BackendWettbewerb($),
    'date-filter': new DateFilter($),
    'encrypted-email': new EncryptedEmail($),
    'google-map': new GoogleMap($),
    'lightbox': new Lightbox($),
    'location-filters': new LocationFilters($),
    'modal': new Modal($),
    'navigation': new Navigation($),
    'photomat-edit': new PhotomatEdit($),
    'placeholder': new Placeholder($),
    'slideshow': new Slideshow($),
    'validator': new FormValidator($)
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Components;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {

      for (var name in Components) {
        if ($('.' + name).length) {
          UTIL.fire(name);
          UTIL.fire(name, 'finalize');
        }
      }
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
