var Modal = function ($) {

    return {

        init: function () {

            $('.modal-trigger-js').on('click', function(event) {
                event.preventDefault();
                $('.modal-content-js').css('display', 'block');
            });

            $('.modal-close-js').on('click', function(event) {
                if (event) {
                    event.preventDefault();
                }
                $('.modal-content-js').css('display', 'none');
                $('.modal-onload-js').css('display', 'none');
            });

            $('.modal-onload-js').css('display', 'block');
        }
    };
};