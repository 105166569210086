var Placeholder = function ($) {

    return {

        init: function () {
            var $widget = $('.placeholder-js');
            var $url = $widget.find('.placeholder-url-js');
            var url = $url.attr('href');
            console.log(url);
            $.ajax({
                url: url,
                success: function(result) {
                    $widget.replaceWith(result);
                }
            });
        }
    };
};