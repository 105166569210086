var Lightbox = function ($) {

    return {

        init: function () {
            $(".lightbox-js").lightGallery({
                selector: ".lightbox-element-js"
            });
            $('.gallery__placeholder').hide();
            $(".gallery__images").show();
        }
    };
};