var BackendWettbewerb = function ($) {

    return {

        init: function () {

			$('.backend-post__button--wettbewerb-js').on('click', function() {
				$('.backend-post__input').prop('checked', true);
			});
        }
    };
};
