var FormValidator = function ($) {

    return {

        init: function () {
            var $validator = $('.validator-js');
            var $referrer = $validator.find('.validator-referrer-js');
            if ($referrer.length) {
                var $result = $validator.append('<input type="text" name="validator-referrer" value="' + $referrer.attr('href') + '">');
            }
        }
    };
};